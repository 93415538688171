.content{
    position: relative;
    display: flex;
    height: 230px;
    width: 100%;
    border: 1px solid #0064A5;
    border-radius: 20px;
    cursor: pointer;
    overflow: hidden;
    img{
       
        object-fit: cover;
        width: 100%;   
    }
    h2{
        position: relative;
        background-color: rgba(0, 100, 165, 0.5);
    }
    figure{
        margin: auto;
    }
}
.text-overlay {
    position: absolute;
    bottom: 20%; /* Exemple : place le texte en bas */
    left: 50%;
    transform: translateX(-50%);
    color: white;
    text-align: center;
    font-size: 17px;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }




@media (min-width: 1000px){
    .text-overlay{
        font-size: 30px;
    }
}