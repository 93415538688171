.hero-section {
    text-align: center;
    background-color: #0064A5;
    color: white;
    padding: 60px 20px;
  }

.butonRdv{
    display: flex;
    justify-content: center;
}
  
  .hero-section h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .hero-section p {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .pricing-section {
    padding: 20px;
    background-color: #f0f0f0;
    text-align: center;
  }
  
  .pricing-table {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .pricing-item {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 30%;
    padding: 20px;
    margin: 10px;
    text-align: center;
  }
  
  .pricing-item h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .pricing-item p {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  .how-it-works-section {
    background-color: #fff;
    text-align: center;
  }
  
  .how-it-works-section ol {
    list-style: decimal inside;
    margin: 20px auto;
    padding: 0;
    max-width: 600px;
  }
  
  .location-contact-section {
    padding: 10px;
    background-color:  #f0f0f0;
    text-align: center;
  }
.location-contact-section iframe {
    border: none;
    margin: 20px 0;
  }
  
  .location-contact-section p {
    margin-bottom: 10px;
  }
  
  .location-contact-section a {
    font-size: 16px;
    font-weight: bold;
  }
  .final-cta-section {
    background-color: #0064A5;
    color: white;
    text-align: center;
    padding: 40px 20px;
  }
  
  .final-cta-section h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .final-cta-section p {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .faq-section{
    display: flex;
    flex-direction: column;
    margin: 0 20px;
  }
  
  
  
  @media (max-width: 768px) {
    .how-it-works-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 20px;
        ol{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    .services-section{
        padding: 10px;
        margin: 0 20px;
    }
    .pricing-table {
      flex-direction: column;
    }
  
    .pricing-item {
      width: 80%;
      margin: 10px auto;
    }

  }
  
  