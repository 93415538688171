.contentQdf{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    img{
        width: 90%;
        border-radius: 10%;
        margin-bottom: 30px;
    }
    h1{
        display: flex;
        
    }
    p{
        margin-bottom: 0%;
        margin-top: 0%;
    }
    h2{
        width: 90%;
        display: flex;
        justify-content: start;
        font-size: 22;
    }
    ul{
        display: flex;
        justify-content: flex-start;
        
    }
   
}

.divContentQdf{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px;
}

@media (min-width: 800px){
 
    .contentQdf{
        align-items: center;
        img{
            width: 30%;
        }
    }
}