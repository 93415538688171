.contentSectionCp {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        display: flex;
        width: 80%;
        border-radius: 10%;
    }

    h1 {
        display: flex;
        justify-content: center;
    }

    ul {
        display: flex;
        flex-direction: column;
    }

    p {
        margin: 2em;
        margin-bottom: 10px;
    }

    h2 {
        width: 90%;
        display: flex;
        justify-content: flex-start;
        font-size: 22px;
    }
}

.divContent {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

@media (min-width: 800px) {
    .contentSectionCp {
        display: flex;
        margin: auto;
        align-items: center;

        img {
            display: flex;
            justify-content: center;
            width: 20%;
        }
    }

    .divContent {
        display: flex;
        align-items: flex-start;
        
    }
}
