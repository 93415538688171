body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;

}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.topContent {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  object-fit: contain;
  width: 100%;
  img{
    
    width: 100%;
    max-height: 500px;
  
  }


}

.contentBlue {
  background-color: #0064A5;
  width: 100%;
  height: 400Px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  a{
    text-decoration: none;
    color:inherit;
    cursor: pointer;
  }
 
}

.contentCity{

  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 23;
  flex-direction: column;
  h1{
    margin-bottom: 0px;
  }
  h2{
    margin-top: 0px;
    font-size: 18px;
  }
}

.contentBubulle{
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  gap:40px;
  margin-top: 40px;
}

.divQuestion{
  display: none;
  object-fit: cover;
  img{
    height: 100%;
  }
  }
@media (min-width: 429px){ 
  

  .topContent{
    img{
      max-height: 1000px;
    }
  }

  .contentBubulle{
   
    width: 90%;
  }

  .contentMiddle{
    display: flex;

  }
  
}

@media (min-width:1000px){
  .contentCity{
    display: none;
  }
  .divQuestion{
    display: block;
  }

  .contentBubulle{
    padding: 50px;
  }
}