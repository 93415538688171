.contentFooter {
    background-color: #0064A5;
    flex-direction: column;
    display: flex;
    font-size: 15px;

    color: white;
    justify-content: space-between;
    margin-top: 40px;
}

.contentFooter p {
    margin-top: 0px;
    align-items: center;
    font-size: 20px;
}

.contentFooter a {
    cursor: pointer;
    color: white;
    text-decoration: none;
}

.contentFooter a:hover {
    color: yellow;
}

.contentFooter i {
    font-size: 50px;
}

.footerTopDiv {
    display: flex;
    gap: 10px;
}
.footerContentTopDiv{
    display: flex;
    justify-content: center;
    position: relative;
    gap: 60px;
    padding: 30px;

}

.footerTopDiv::after {
    content: ""; /* Obligatoire pour le pseudo-élément */
    width: 60%; /* Largeur du trait (ajustez si nécessaire) */
    border-bottom: solid 2px rgb(255, 238, 0); /* Style du trait */
    position: absolute; /* Positionnement libre */
    bottom: 0; /* Place le trait au bas de .footerTopDiv */
    left: 50%; /* Centre le trait horizontalement */
    transform: translateX(-50%); /* Ajuste pour centrer */
}



.footerDiv {
    display: flex;
    justify-content: space-around;
    text-decoration: none;
    padding: 20px;
    li{
        list-style: none;
    }
  
}

.footerDiv h2 {
    position: relative; 
    /* Nécessaire pour positionner le ::after */
}

.footerDiv h2::after {
    content: ""; /* Obligatoire pour afficher le pseudo-élément */
    width: 50px;
    border-bottom: solid 2px yellow;
    position: absolute;
    bottom: -10px; /* Ajuste la position sous le h2 */
    left: 20%;
    transform: translateX(-50%); /* Centre le trait horizontalement */
}
.footerBottomContent{
    display: flex;
    flex-direction: column;
}

.footerDivTwo {
    display: flex;
    align-items: center;
    padding: 30px;
    flex-direction: column;
}

@media (min-width: 1000px) {
    .contentFooter {
        margin-top: 0px;
    }
}
@media (max-width: 850px) {
    .footerTopDiv{
        flex-direction: column;
        align-items: center;
        
    }
}

@media (max-width: 500px) {
    .footerContentTopDiv{
        display: flex;
        flex-direction: column;
    }
    .footerDiv{
        display: flex;
        flex-direction: column;
    }
}
