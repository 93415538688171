.contentTp{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
    img{
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.contentBotTp{
    margin: 0 20px;
    h2{
        margin-bottom: 2%;
    }
}

@media (max-width:700px){
    .contentTp{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    img{
        width: 90%;
        border-radius: 10%;
    }
    h1{
        display: flex;
        
    }
    p{
       
        margin: 2em;
        margin-bottom: 10px;
    }
    h2{
        width: 90%;
        display: flex;
        justify-content: start;
        font-size: 22;
    }
}
}