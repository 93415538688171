.App {
    text-align: center;
    margin: 20px;
    min-height: 100vh;
  }
  .question-text{
    font-size: 20px;
    
  }
  .score-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    li{
      margin-bottom: 30px;
    }
   
  }

  .question-image{
   img{
    object-fit: cover;
    max-width: 100%;
    height: auto;
   }

  
 

  }
  
  .question-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    gap:10px;
   
  }
  
  .question-count {
    margin-bottom: 10px;
    font-weight: bold;
  }
 
  .answer-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  button {
    margin: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: 2px solid #007BFF;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .congratulation{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .centerButton{
    display: flex;
    flex-direction: column;
    align-items: center;
  }