.buttonContent {
    background-color: #EEEEEE;
    width: 80%;
    height: 100px;
    display: flex;
    justify-content: center;
    text-decoration: none;
    border-radius: 8px;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    img{
        position: relative;
        left : 30px;
        width: 50px;
    }
    a{
        text-decoration: none;
    }
}

.buttonTwoContentRed{ 
    background-color: #EEEEEE;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    text-decoration: none;
    border-radius: 8px;
    align-items: center;
    font-weight: 600;
    font-size: 30px;
    color: red;
    border: solid;
    img{
        position: relative;
        left : 30px;
        width: 50px;
    }
    a{
        text-decoration: none;
    }
    
}  




@media (max-width:800px){
    .buttonTwoContentRed{
    font-size: 20px;
    display: flex;
        width: 80%;
    }
}
    
